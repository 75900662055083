const blogRootData = {
  url: "/seattle-wedding-photographer-blog",
  title: "Washington Elopement Photographer Blog",
}

const paths = {
  home: {
    url: "/",
    title: "Washington Elopement Photographer",
  },
  howToElope: {
    url: "/how-to-elope-in-washington",
    title: "How to Elope in Washington",
  },
  contact: {
    url: "/contact-washington-elopement-photographer/",
    title: "Contact Washington Elopement Photographer",
  },
  blog: blogRootData,
  portfolio: {
    url: "/washington-elopement-photos/",
    title: "Washington Elopement Photographer Photos",
  },
  portfolioCouples: {
    url: "/portfolio/seattle-adventure-wedding-photographer/",
    title: "Washington Elopement Photographer Couples Photos",
  },
  portfolioWeddings: {
    url: "/portfolio/washington-adventure-wedding-photographer/",
    title: "Washington Elopement Photographer Wedding Photos",
  },
  pricing: {
    url: "/washington-elopement-photographer-pricing/",
    title: "Washington Elopement Photographer Pricing",
  },
  about: {
    url: "/about-seattle-wedding-photographer/",
    title: "About Washington Elopement Photographer",
  },
  privacyPolicy: {
    url: "/privacy-policy/",
    title: "Privacy Policy for Photography by Betty Elaine's Website",
  },
}

export default paths
