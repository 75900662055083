import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavItemLi = styled.li`
  text-transform: capitalize;
  font-weight: 900;
  letter-spacing: -0.5px;
  position: relative;

  a {
    padding-bottom: 20px;
    display: block;
    color: var(--brand-color-green);
    text-decoration: none;
    transition: var(--transition) color;
    font-family: var(--header-font-families);
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  &[data-primary] a {
    color: var(--brand-color-yellow);
  }

  @media (min-width: 768px) {
    margin-right: 15px;
    margin-top: 22px;
    margin-bottom: 0;

    &:nth-child(2) {
      width: 50px;
      text-align: center;
      line-height: 1rem;
      margin-top: 17px;
    }

    &:nth-child(4) {
      margin-right: 330px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      color: var(--brand-color-off-white);
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0;
      font-size: 0.8rem;
    }

    &:hover {
      border-bottom: var(--brand-color-off-white) 2px solid;
    }

    &[data-primary]:hover {
      border-bottom-color: var(--brand-color-yellow);
    }
  }

  @media (min-width: 1024px) {
    margin-right: 30px;

    a {
      font-size: 1rem;
    }

    &:nth-child(4) {
      margin-right: 370px;
    }
  }

  @media (min-width: 1440px) {
    margin: 16px;

    a {
      font-size: 1.5rem;
    }

    &:nth-child(2) {
      width: 80px;
      line-height: 1.5rem;
      margin-top: 7px;
    }
  }
`

const NavItem = ({ setNav, index, item }) => {
  const linkClicked = e => {
    setNav(false)
  }

  const getLink = () => {
    if (item.text === 'Blog') {
      return (<a href={item.path}>
        {item.text}
      </a>)
    }
  
    return (<Link to={item.path} onClick={linkClicked}>
      {item.text}
    </Link>)
  }

  const link = getLink(item);

  return (
    <>
      <NavItemLi key={index} data-primary={item.primary}>
        {link}
      </NavItemLi>
    </>
  )
}

export default NavItem
