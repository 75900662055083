import React from "react"
import styled from "styled-components"
import NavItems from "../Navbar/NavItems"
import paths from "../../constants/paths"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const footerContent = `
  Photography by Betty Elaine is a Seattle Adventure Wedding and Elopement 
  Photographer in the Pacific Northwest. Servicing weddings throughout 
  Washington and National Parks, including Snoqualmie Pass, Mount Rainier 
  National Park, Olympic Peninsula, North Cascades, and San Juan Island. 
  Betty's professional work features beautiful editorial wedding 
  photography, elopements, and anniversary sessions. Travel is available 
  to other locations outside of the Washington area for local, national, 
  and international wedding photography. As a wedding photographer with a 
  passion for adventure and travel photography, Betty has photographed 
  across the United States, and Europe.
`;

const Nav = styled.div`
  margin: 1rem;

  ul li {
    margin-left: 0;
    border-bottom: transparent 2px solid;

    &[data-primary]:hover, &:hover {
      border-bottom-color: transparent;
    }

    a {
      color: var(--brand-color-green);
      text-align: center;
    }
  }

  @media (min-width: 425px) {
    ul {
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;

      li {
        width: 28vw;
        padding-left: 1rem;
        &.break {
          flex-basis: 100%;
          height: 0;        
        }


        &:first-child {
          padding-left: 0;
        }
        &:nth-child(4):after {
          content: '';
          width: 300px;
        }
      }
    }
  }

  @media (min-width: 550px) {
    ul li {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    ul {
      li {
        margin-top: 0;
        margin-bottom: 2rem;
      }

      li:nth-child(4) {
        margin-right: 25px;
      }
      li:nth-child(2) {
        margin-top: 0;
        width: auto;
        line-height: 1.3;
      }
    }
  }

  @media (min-width: 1024px) {
    ul {
      // li:nth-child(2) {
      //   margin-top: -5px;
      //   width: 70px;
      // }
    }
  }
`;

const Div = styled.div`
  position: relative;
  z-index: 1;
  background: var(--background);
  p {
    text-align: left;
  }
`

const Article = styled.article`
  p {
    text-align: center;
  }

  .copyright {
    font-size: 3.5vw;

    @media (min-width: 450px) {
      font-size: 1rem;
    }
  }
`;

const EndBlurb = styled.div`
  p {
    // Minimum recommended size by Google for SEO
    font-size: 12px;
  }

  @media (min-width: 768px) {
    max-width: 900px;
    margin: 0 auto;
    p {
      text-align: left;
    }
  }
`

const LogoWrapper = styled.div`
  margin: 0;
  text-align: center;
  margin-top: 2rem;

  .gatsby-image-wrapper, img {
    max-width: 320px;
  }
`;

const FollowWrapper = styled.div`
  h2 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ul {
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none;
  
    li {
      margin-left: 0;
      width: 100%;
      margin-bottom: 10px;
      display: inline;
      font-size: 2rem;
      padding: 7px;
      
      a {
        text-decoration: none;
        text-align: center;
        color: var(--brand-color-black);
      }
    }
  } 
`

const SeaToMountain = styled.article`
  max-width: 320px;
  margin: 0 auto;
  border-bottom: 1px solid var(--brand-color-light-blue);
  
    @media (min-width: 768px) {
      max-width: 560px;
    }
  
    @media (min-width: 1024px) {
      max-width: 770px;
    }
  `

const Email = styled.div`
  padding-bottom: 1rem;
  h2 {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    margin: 0;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  a {
    font-size: 0.85rem;
    font-weight: bold;
  }

  @media (min-width: 768px) {
    h2 {
      margin-top: 0;
      padding-top: 0;
    }
    a {
      font-size: .6rem;
    }
  }

  @media (min-width: 900px) {
    a {
      font-size: .8rem;
    }
  }

  @media (min-width: 1024px) {
    a {
      font-size: .95rem;
    }
  }
`

const Lgbtq = styled.div`
  max-width: 320px;
  margin: 0 auto;

  ul {
    list-style: none;
    text-align: center;

    li {
      font-size: 1rem;
      max-width: 200px;
      padding: 0.5rem 0;
      margin: 0 auto;
      border-bottom: 1px solid var(--brand-color-light-blue);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media (min-width: 768px) {
    ul {
      margin-top: 0;
    }
  }
`

const Ferry = styled.div`
  margin-top: -20px;
  margin-bottom: -20px;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: -80px;
    margin-bottom: -50px;
  }
`

const ExpertiseDotCom = styled.div`
  text-align: center;
`

const Columns = styled.div`
  @media (min-width: 768px) {
    margin: 2rem auto;
    max-width: 1024px;
  }
`

const FooterLayout = () => {
  return (
    <Div>
      <LogoWrapper className="text-center">
        <Link to="/">
          <StaticImage alt="Photography by Betty Elaine Logo" src="../../images/logos/Alt-Logo-2-green-1000px.png" width={400} />
        </Link>
      </LogoWrapper>
      <SeaToMountain>
        <h2>
          Sea to Mountain Adventure Wedding & Elopement Photography
        </h2>
      </SeaToMountain>
      <Article>
        <Columns className="pure-g">
          <FollowWrapper className="pure-u-1-3 pure-u">
            <h2>Follow Me</h2>
            <ul>
              <li>
                <a href="https://www.facebook.com/bettyelainephotography" alt="Facebook">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/photosbybetty/" alt="Instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/photosbybetty/" alt="Pinterest">
                  <i className="fab fa-pinterest"></i>
                </a>
              </li>
            </ul>
          </FollowWrapper>
          <Email className="pure-u-1-3 pure-u">
            <h2>Email me</h2>
            <p>
              <Link to={paths.contact.url}>contact<i className="fas fa-at"></i>bettyelainephotography.com</Link>
            </p>
          </Email>
          <Lgbtq className="pure-u-1-3 pure-u">
            <ul>
              <li>LGBTQ+ Friendly</li>
              <li>BIPOC Inclusive</li>
              <li>All skin colors & body sizes are welcomed</li>
            </ul>
          </Lgbtq>
        </Columns>
        <Ferry>
          <StaticImage src="../../images/illustrations/Ferry-color-large.png" width={700}  alt="Northwest Ferry Illustration"/>
        </Ferry>
        <Nav>
          <NavItems />
        </Nav>
        <p>
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Let's Chat!</Link>
        </p>
        <ExpertiseDotCom>
          <a href="https://www.expertise.com/wa/seattle/wedding-photography">
            <StaticImage width={200} src="../../images/wa_seattle_wedding-photography_2022_transparent.webp" alt="Top Wedding Photographer in Seattle" />
          </a>
        </ExpertiseDotCom>
        <p className="copyright">
          Copyright &copy; {new Date().getFullYear()}{" "} Photography by Betty Elaine LLC | <Link to={paths.privacyPolicy.url}>Privacy Policy</Link>
        </p>
        <EndBlurb>
          <p>
            {footerContent}
          </p>
        </EndBlurb>
      </Article>
    </Div>
  )
}

export default FooterLayout
