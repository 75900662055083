import React from "react"
import Navbar from "./Navbar/Navbar"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
import "../../static/vendor/icomoon/style.css"
import "../../static/vendor/pure.2.0.6.css"
import FooterLayout from "./Footer/FooterLayout"
const GoogleTagManager = loadable(() => import("./GoogleTagManager"))

const GlobalStyle = createGlobalStyle`
:root {
  // Brand Colors:
  --brand-color-green: #094421;
  --brand-color-light-blue: #a8c1c0;
  --brand-color-maroon: #622513;
  --brand-color-off-white: #f7f6ed;
  --brand-color-yellow: #cc8c20;
  --brand-color-white: #fff;
  --brand-color-black: #212529;

  // Brand Fonts
  --brand-font-body: montserrat, sans-serif;
  --brand-font-heading: moret, serif;

  // Template Colors
  --background: var(--brand-color-white);
  --body-font-color: var(--brand-color-black);
  --border: var(brand-color-maroon);
  --primary: var(--brand-color-green);
  --inActive: var(--brand-color-light-blue);

  // Responsive widths
  --responsive-tablet-width: 768px;
  --responsive-laptop-width: 1200px;
  --responsive-desktop-width: 1600px;
  --responsive-xldesktop-width: 2000px;

  // Fonts
  --body-font-families: var(--brand-font-body);
  --header-font-families: var(--brand-font-heading);
  --subheader-font-families: var(--brand-font-heading);
  // Sizes
  --h1: 2rem;
  --h2: 1.5rem;
  --font-size: 1rem;
  @media(min-width: 768px) {
    --h1: 2.5rem;
    --h2: 2.5rem;
    --font-size: 1.25rem;
  }
  @media(min-width: 1200px) {
    --h1: 3.5rem;
    --h2: 3.5rem;
    --font-size: 1.5rem;
  }

  // Spacing
  --menuItem: 1.125rem;
  --paddingBorder: 1.875rem;
  --paddingStd: 3.125rem;
  --paddingLarge: 4.688rem;

  // Tablet
  @media(min-width:var(--tablet-width)) {
    --paddingStd: 4.688rem;
    --paddingLarge: 7.813rem;
  }

  @media(min-width: 1200px) {
    --menuItem: 1.25rem;
    --paddingStd: 5.625rem;
    --paddingLarge: 9.375rem;
  }

  // Transitions
  --transition: 0.3s;
}

// Grid For Mobile
@media (max-width: 767px) {
  .pure-g .pure-u {
    width: 100%;
  }
}

// Global
* {
  box-sizing: border-box;
  font-family: var(--body-font-families);
}

body {
  font-family: var(--body-font-families);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--body-font-color);
  font-size: var(--font-size);
  line-height: 1.3;

  &.noscroll {
    overflow: hidden;
  }
}

// Headings
h1, h2, h3, h4, h5, h6 {
  text-align: center;
  line-height: 1.3;
  padding: 7px 15px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h1, h2 {
  font-weight: 500;

  @media(min-width: 1200px) {
    margin-bottom: 1.5rem;
  }
}

h1 {
  font-size: var(--h1);
  font-weight: 900;
  font-family: var(--header-font-families);
}

h2 {
  font-size: var(--h2);
  font-weight: 500;
  font-family: var(--header-font-families);
}

h3, h4, h5, h6 {
  font-family: var(--subheader-font-families);
}

h3 {
  font-size: 1.3rem;
  font-family: var(--subheader-font-families);
  text-transform: uppercase;
}

h4 {
  font-size: 1.3rem;
}

// Buttons
.pure-button {
  font-family: var(--brand-font-heading);
  text-transform: uppercase;
}
.pure-button-primary, .pure-button-selected, a.pure-button-primary, a.pure-button-selected {
  background: var(--brand-color-yellow);
}
.button-secondary {
  background: var(--brand-color-light-blue);
  color: var(--brand-color-white);
}
.full-touch-button {
  width: 100%;
}

@media (min-width: 480px) {
  .full-touch-button {
    width: auto;
  }
}

// Common Elements
a {
  color: var(--brand-color-maroon);
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
}

article p, section p {
  padding: 0 1rem;
  text-align: justify;
  text-justify: none;
}

article ul, section ul {
  padding: 0 1rem;
  margin-left: 1rem;
}

// Commons
.text-center, article p.text-center, section p.text-center {
  text-align: center;
}

article {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

article p, article ul {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

article ul li {
  margin-left: 2rem;
}
`

const Layout = ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link async rel="stylesheet" href="https://use.typekit.net/fcq7jph.css" />
      </Helmet>
      <GoogleTagManager/>
      <GlobalStyle />
      <Navbar />
      {children}
      <FooterLayout />
    </>
  )
}

export default Layout
