import React from "react"
import styled from "styled-components"
import navLinks from "../../constants/navLinks"
import NavItem from "./NavItem";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  @media (min-width: 1440px) {
    margin-right: 80px;
  }
`

const NavItems = ({ setNav }) => {
  return (
    <>
      <Ul>
        {navLinks.map((item, index) => {
          return (<NavItem setNav={setNav} key={index} item={item}/>)
        })}
      </Ul>
    </>
  )
}

export default NavItems
