import paths from "./paths";

const navLinks = [
  {
    path: paths.home.url,
    text: "Home",
  },
  {
    path: paths.howToElope.url,
    text: "How To Elope",
  },
  {
    path: paths.about.url,
    text: "About",
  },
  {
    path: paths.pricing.url,
    text: "Pricing"
  },
  {
    path: paths.portfolio.url,
    text: "Portfolio",
    items: [
      {
        path: paths.portfolioWeddings.url,
        text: "Weddings"
      },
      {
        path: paths.portfolioCouples.url,
        text: "Couples"
      }
    ]
  },
  {
    path: paths.contact.url,
    text: "Contact",
    primary: true
  },
  {
    // path: "/seattle-wedding-photographer-blog/",
    path: paths.blog.url,
    text: "Blog",
  }
];

export default navLinks;