import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import NavItems from "./NavItems";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const logoImage = "../../images/logos/Primary Logo-cream-1000px.png";
const FerryImg = "../../images/illustrations/Ferry-color-medium.png"

const logoHeight = 60;

const NavLogo = styled.div`
  position: relative;
  z-index: 50;
  transform: scale(.85);
  padding: 0.5rem 0;
  margin: 0 auto;
  width: 264px;

  img {
    height: ${logoHeight}px;
    margin-right:-20px;
  }

  @media (min-width: 768px) {
    position: relative;
    transform: scale(1);
  }
`

const NavBar = styled.nav`
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: var(--brand-color-green);

  &.show-nav {
    i {
      color: var(--brand-color-black);
    }
  }

  @media (min-width: 768px) {
    position: relative;
  }
`

const NavButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  font-size: var(--menuItem);
  z-index: 101;
  height: 75px;
  padding: 32px 1.25rem;
  color: var(--brand-color-white);

  @media (min-width: 768px) {
    display: none !important;
  }
`

const NavCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-}: space-between;
  margin: 0 auto;

  @media (min-width: 768px) {
    display: block;
  }
`

const NavLinks = styled.div`
  background-color: var(--background);
  z-index: 100;
  display: flex;
  align-content: center;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  padding: 0 1.875rem;
  margin: 0;
  visibility: hidden;
  width: 100%;
  max-width: 500px;
  transform: translateX(-100%);
  transition: transform 0.2s, visibility var(--transition);

  @media (min-width: 768px) {
    z-index: 30;
    position: absolute;
    transform: translate(0);
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    padding: 0;
    display: block;
    max-width: inherit;
    background: none;
  }

  &.show-nav {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
`

const Spacer = styled.div`
  height: 76px;

  @media (min-width: 768px) {
    height: 0;
  }
`

const FerryImgDiv = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

// TODO: Nav issues possibly caused by the error below:
// in console on page load: "Expected server HTML to contain a matching <div> in <div>."
// https://stackoverflow.com/questions/46443652/react-16-warning-expected-server-html-to-contain-a-matching-div-in-body
const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <NavBar className={
              isOpen ? `${"show-nav"}` : ``
            }>
        <NavCenter>
          <NavButton type="button" onClick={toggleNav} alt="Navigation">
            <i className="fas fa-bars"></i>
          </NavButton>
          <NavLogo>
            <Link cover="true" to="/">
              <StaticImage src={logoImage} alt={siteMetadata.title} height={logoHeight} style={{display: 'block'}}/>
            </Link>
          </NavLogo>
          <NavLinks
            className={
              isOpen ? `${"navbar-links"} ${"show-nav"}` : `${"navbar-links"}`
            }
          >
            <NavItems setNav={setNav} />
            <FerryImgDiv>
              <StaticImage alt="Northwest Ferry illustration" src={FerryImg} width={320}/>
            </FerryImgDiv>
          </NavLinks>
        </NavCenter>
      </NavBar>
      <Spacer />
    </>
  )
}

export default Navbar
